import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type KeyboardEvent, type MouseEvent, type ReactNode, useState } from 'react'
import { useTheme } from 'styled-components'

import { type AcceptAllSource } from '@/components/shared/cookie-permissions/CookiePermissions'
import {
  AlwaysActiveLabel,
  CookieConsentButtons,
  CookieOptionWrapper,
  StyledToggle,
} from '@/components/shared/cookie-permissions/styled'
import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'
import useCookiePreferences from '@/hooks/useCookiePreferences'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { Accordion, AccordionItem } from '@/ui/atoms/accordion'
import { BodyText } from '@/ui/atoms/body-text'
import { Button } from '@/ui/atoms/button'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { PlusMinus } from '@/ui/atoms/plus-minus'
import { Spacing } from '@/ui/atoms/spacing'
import { Modal, ModalBody, ModalFooter } from '@/ui/molecules/modal/Modal'
import { boolToBinary } from '@/utils/conversions'

const COOKIE_CONSENT_OPTION_TYPE = {
  NECESSARY: 'necessary',
  ANALYTICS: 'analytics',
  TARGETING: 'targeting',
}

type CookieConsentOptionType =
  (typeof COOKIE_CONSENT_OPTION_TYPE)[keyof typeof COOKIE_CONSENT_OPTION_TYPE]

type CookieConsentOption = {
  id: CookieConsentOptionType
  title: string
  description: string
  controls: ReactNode
}

export type CookieConsentModalProps = {
  isOpen: boolean
  handleAcceptAll: (source: AcceptAllSource) => void
  handleClose: () => void
}

export const CookieConsentModal = ({
  isOpen,
  handleAcceptAll,
  handleClose,
}: CookieConsentModalProps) => {
  const { t } = useTranslation('common')
  const { spacing, colors } = useTheme()
  const { pathname } = useRouter()
  const [optOutAnalyticsAndPerformance, setOptOutAnalyticsAndPerformance] = useState(false)
  const [optOutTargeting, setOptOutTargeting] = useState(false)
  const { setCookiePreferences } = useCookiePreferences()

  const handleToggle = (e: MouseEvent | KeyboardEvent, optionId: CookieConsentOptionType) => {
    /* We have to stop event propagation to prevent the event from bubbling up to the accordion button and opening the accordion item */
    e.stopPropagation()

    if (optionId === COOKIE_CONSENT_OPTION_TYPE.ANALYTICS) {
      setOptOutAnalyticsAndPerformance(!optOutAnalyticsAndPerformance)
      return
    }

    setOptOutTargeting(!optOutTargeting)
  }

  const cookieConsentOptions: CookieConsentOption[] = [
    {
      id: COOKIE_CONSENT_OPTION_TYPE.NECESSARY,
      title: t('cookies.consent.manage_preferences.necessary.title'),
      description: t('cookies.consent.manage_preferences.necessary.description'),
      controls: (
        <AlwaysActiveLabel color={colors.purple.primary}>
          {t('cookies.consent.manage_preferences.necessary.controls')}
        </AlwaysActiveLabel>
      ),
    },
    {
      id: COOKIE_CONSENT_OPTION_TYPE.ANALYTICS,
      title: t('cookies.consent.manage_preferences.analytics.title'),
      description: t('cookies.consent.manage_preferences.analytics.description'),
      controls: (
        <StyledToggle
          id={COOKIE_CONSENT_OPTION_TYPE.ANALYTICS}
          ariaLabel={t('cookies.consent.manage_preferences.analytics.title')}
          isActive={!optOutAnalyticsAndPerformance}
          onClick={(e) => handleToggle(e, COOKIE_CONSENT_OPTION_TYPE.ANALYTICS)}
        />
      ),
    },
    {
      id: COOKIE_CONSENT_OPTION_TYPE.TARGETING,
      title: t('cookies.consent.manage_preferences.targeting.title'),
      description: t('cookies.consent.manage_preferences.targeting.description'),
      controls: (
        <StyledToggle
          id={COOKIE_CONSENT_OPTION_TYPE.TARGETING}
          ariaLabel={t('cookies.consent.manage_preferences.targeting.title')}
          isActive={!optOutTargeting}
          onClick={(e) => handleToggle(e, COOKIE_CONSENT_OPTION_TYPE.TARGETING)}
        />
      ),
    },
  ]

  const renderCookieConsentOptions = (option: CookieConsentOption, expanded: boolean) => {
    return (
      <CookieOptionWrapper gap={spacing.default} alignItems="center">
        <PlusMinus isActive={expanded} />
        {option.title}
        {option.controls}
      </CookieOptionWrapper>
    )
  }

  const handleSavePreferences = () => {
    setCookiePreferences({
      optOutTargeting: optOutTargeting,
      optOutAnalyticsAndPerformance: optOutAnalyticsAndPerformance,
    })

    trackEvent({
      action: GA_EVENT.CookiePreferenceSave,
      label: `cta=save_preferences;loc=${pathname};FunctionalCookie=1;AnalyticsCookie=${boolToBinary(
        !optOutAnalyticsAndPerformance
      )};TargetingCookie=${boolToBinary(!optOutTargeting)}`,
    })

    handleClose()
  }

  return (
    <Modal
      data-testid="cookie-consent-modal"
      hasCloseButton
      id="cookie-consent-modal"
      isOpen={isOpen}
      label="Cookie consent modal"
      onCancel={handleClose}
      padding={`${spacing.large} ${spacing.large}`}
    >
      <Spacing mTop={spacing.mini} mBottom={spacing.large}>
        <HeadlineText size="large">{t('cookies.consent.manage_preferences.title')}</HeadlineText>
      </Spacing>

      <ModalBody>
        <BodyText size="small">
          {t('cookies.consent.manage_preferences.description')}
          <LinkCustom
            hasUnderline
            key="cookiePolicyLink"
            rel="noreferrer"
            size="small"
            target="_blank"
            variant="secondary"
            href={`${t('cookies.banner.cookie_policy.kijiji.href')}`}
          >
            {t('cookies.consent.policy')}
          </LinkCustom>
          .
        </BodyText>

        <Spacing mTop={spacing.large}>
          <HeadlineText size="medium">
            {t('cookies.consent.manage_preferences.category_title')}
          </HeadlineText>
        </Spacing>
        <Spacing mTop={spacing.default}>
          <Accordion>
            {cookieConsentOptions.map((option) => (
              <AccordionItem
                key={option.id}
                id={option.id}
                renderAlternateButtonContent={(expanded) =>
                  renderCookieConsentOptions(option, expanded)
                }
              >
                {option.description}
              </AccordionItem>
            ))}
          </Accordion>
        </Spacing>
      </ModalBody>

      <ModalFooter>
        <CookieConsentButtons>
          <Button
            variant="secondary"
            size="small"
            isFullWidth
            onClick={() => handleAcceptAll('consentModal')}
          >
            {t('cookies.consent.manage_preferences.accept_all')}
          </Button>
          <Button size="small" isFullWidth onClick={handleSavePreferences}>
            {t('cookies.consent.manage_preferences.save_preferences')}
          </Button>
        </CookieConsentButtons>
      </ModalFooter>
    </Modal>
  )
}
