import { type NormalizedCacheObject, ApolloClient, from } from '@apollo/client'
import merge from 'deepmerge'
import { type IncomingHttpHeaders } from 'http'
import isEqual from 'lodash/isEqual'
import { type NextApiRequestCookies } from 'next/dist/server/api-utils'
import { useMemo } from 'react'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { type LanguageKey, getLocale } from '@/domain/locale'
import { initializeCache, writeInitialData } from '@/lib/apollo/cache'
import contextLink from '@/lib/apollo/middlewares/contextLink'
import errorLink from '@/lib/apollo/middlewares/errorLink'
import logger from '@/lib/apollo/middlewares/logger'
import { deserializeCookies } from '@/utils/cookies/deserializeCookies'
import { isServer } from '@/utils/isSSR'

// @ts-expect-error this import is resolved by webpack at compilation, depending on the runtime in question - see next.config.ts for implementation
// eslint-disable-next-line import/no-unresolved, @kijiji/enforce-path-alias
import httpLink from './middlewares/httpLink-ClientOrServer'

export type RequestHeaders = {
  headers?: IncomingHttpHeaders
  cookies?: NextApiRequestCookies
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let storedApolloClient: ApolloClient<NormalizedCacheObject> | null = null

/**
 * Creates an instance of ApolloClient with customized configurations.
 *
 * This function initializes an ApolloClient with specific settings for caching, development tools,
 * linking, query deduplication, and server-side rendering mode based on the provided options.
 * It optionally initializes the cache with initial data if no initialState is provided.
 *
 * @param {Object} config - The configuration object for creating the ApolloClient.
 * @param {LanguageKey} config.languageKey - The key representing the current language, used in context link.
 * @param {RequestHeaders} [config.requestHeaders] - Optional request headers for the client.
 * @param {NormalizedCacheObject} [config.initialState] - The initial state to hydrate the ApolloClient cache with.
 * @returns {ApolloClient} An instance of ApolloClient configured with the provided options.
 */
const createApolloClient = ({
  languageKey,
  requestHeaders,
  initialState,
}: {
  languageKey: LanguageKey
  requestHeaders?: RequestHeaders
  initialState?: NormalizedCacheObject
}) => {
  const isDevMode = !isServer() && process.env.NODE_ENV !== 'production'

  const cache = initializeCache()

  if (initialState) {
    cache.restore(initialState)
  } else {
    writeInitialData(cache, requestHeaders?.cookies)
  }

  return new ApolloClient({
    cache,
    connectToDevTools: isDevMode,
    link: from([contextLink(languageKey, requestHeaders), errorLink, ...logger(), httpLink()]),
    queryDeduplication: true,
    ssrMode: isServer(),
  })
}

/**
 * Initializes and returns an ApolloClient instance with optional initial state and request headers.
 *
 * This function creates or reuses an ApolloClient instance based on the provided initial state and request headers.
 * It handles server-side and client-side initialization differently to accommodate SSR (Server-Side Rendering) and CSR (Client-Side Rendering).
 * On the server, it always creates a new instance. On the client, it reuses a stored instance if available.
 * The function also merges any provided initial state with the existing cache state to prevent overwriting data.
 *
 * @param {Object} [props] - Optional properties for initializing the ApolloClient.
 * @param {NormalizedCacheObject} [props.initialState] - The initial state to hydrate the ApolloClient cache with.
 * @param {RequestHeaders} [props.requestHeaders] - Headers to be used for requests made by the ApolloClient.
 * @returns {ApolloClient} The initialized ApolloClient instance.
 */
export const initializeApollo = (props?: {
  initialState?: NormalizedCacheObject
  requestHeaders?: RequestHeaders
}) => {
  const { initialState, requestHeaders } = props || {}

  // If the ApolloClient instance already exists and we have initialState provided,
  // merge new and existing cache states to prevent overwriting data
  if (storedApolloClient) {
    if (initialState) {
      const existingCache = storedApolloClient.cache.extract()

      const data = merge(existingCache, initialState, {
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
        ],
      })

      storedApolloClient.cache.restore(data)
    }
    return storedApolloClient
  }

  const cookies = isServer() ? requestHeaders?.cookies : deserializeCookies(document?.cookie)
  const cookieLocale = cookies?.[CookieRegistry.SITE_LOCALE]

  const { languageKey } = getLocale(cookieLocale)

  const apolloClient = createApolloClient({
    languageKey,
    requestHeaders: { ...requestHeaders, cookies },
    initialState,
  })

  // We never want to store the Apollo Client on the server, each request is fresh instance.
  if (isServer()) {
    return apolloClient
  }

  if (!storedApolloClient) {
    storedApolloClient = apolloClient
  }

  return storedApolloClient
}

/**
 * Adds Apollo client state to the page properties.
 *
 * This function takes an ApolloClient instance and optionally page properties, then returns
 * the page properties with the Apollo client's cache state added under a specific property name.
 * It's primarily used for injecting Apollo state into page props for Next.js applications to enable
 * state hydration on the client side.
 *
 * @template T - The type of the page properties, extending a record of string keys to unknown values.
 * @param {ApolloClient<NormalizedCacheObject>} client - The ApolloClient instance from which to extract the cache state.
 * @param {T} [pageProps] - Optional page properties to which the Apollo state will be added.
 * @returns {T & { [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject }} - The page properties with the Apollo state added.
 */
export const addApolloState = <T extends Record<string, unknown>>(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps?: T
): T & { [APOLLO_STATE_PROP_NAME]: NormalizedCacheObject } => {
  return { ...(pageProps ?? ({} as T)), [APOLLO_STATE_PROP_NAME]: client.cache.extract() }
}

/**
 * Custom hook for initializing and using an ApolloClient instance within a React component.
 *
 * This hook abstracts the logic for initializing an ApolloClient with potentially preloaded state
 * from server-side rendering or static generation. It leverages React's useMemo hook to memoize
 * the ApolloClient instance, ensuring that it is only reinitialized if the state changes.
 *
 * @param {Object} [pageProps] - Optional properties passed to the component, which may include an initial Apollo state.
 * @param {NormalizedCacheObject} [pageProps.APOLLO_STATE_PROP_NAME] - The initial state for the Apollo client cache, if any.
 * @returns {ApolloClient<NormalizedCacheObject>} An ApolloClient instance initialized with the provided or existing state.
 */
export const useApollo = (pageProps?: { [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject }) => {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME]
  const client = useMemo(() => initializeApollo({ initialState: state }), [state])

  return client
}

/**
 * Resets the stored ApolloClient instance to null.
 *
 * This function is primarily intended for use in testing environments where it's necessary to clear
 * the stored ApolloClient instance between tests to ensure test isolation and prevent unintended state
 * persistence across tests.
 */
export const resetStoredApolloClient = () => (storedApolloClient = null)
