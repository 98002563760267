// https://wiki.es.ecg.tools/display/FE/Cookies

// Cookies need to be registered here to be used by the getCookie and createCookie functions
export enum CookieRegistry {
  ALGOLIA_TOKEN = 'algoliaToken',
  GOOGLE_MAPS_API_KEY = 'gmk',
  GOOGLE_PLACE_TOKEN = 'modane',
  MACHINE_ID = 'machId',
  HASHED_USER_ID = 'huid',
  SITE_LOCALE = 'siteLocale',
  USER_PREFERENCES = 'up',
  COOKIE_PREFERENCES = 'cp',
  HAS_DISPLAYED_SAVE_SEARCH_BANNER = 'hdssb',
  HAS_SAVED_SEARCH = 'hss',
  HAS_DISMISSED_PSA_BANNER = 'kj-dismiss',
  MANUALLY_UPDATED_RADIUS = 'mur',

  // Auth related
  ACCESS_TOKEN = 'kj-at',
  REFRESH_TOKEN = 'kj-rt',
  SESSION_JWT = 'kj-st',
  LEGACY_SESSION_COOKIE = 'ssid',
}

export enum UpCookieRegistry {
  LATEST_SEARCH = 'ls',
}

export enum LatestSearchCookieRegistry {
  ADDRESS = 'address',
  LATITUDE = 'lat',
  LOCATION_ID = 'l',
  LONGITUDE = 'lon',
  RADIUS = 'r',
  SORT_KEY = 'sf',
  SRP_VIEW = 'sv',
}
