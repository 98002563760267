import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react'

import { ResponsiveProp, StyledAs } from '@/ui/typings/helpers'

import { Loading } from '../loading'
import { ButtonBase } from './styled'

export type ButtonSizes = 'medium' | 'small'

type LoadingProps =
  | { isLoading?: boolean }
  | {
      isLoading?: false
      loadingLabel?: never
    }
  | {
      isLoading: true
      loadingLabel: string
    }

type AsButtonProps = {
  as?: 'button' | StyledAs
} & ButtonHTMLAttributes<HTMLButtonElement>

type AsLinkProps = {
  as: 'a'
} & AnchorHTMLAttributes<HTMLAnchorElement>

export type ButtonProps = {
  /**
   * Specify button taking full with of container
   * @default false
   */
  isFullWidth?: boolean
  /**
   * Specify loading button state
   * @default false
   */
  isLoading?: boolean
  /**
   * Specify loading button label required only when isLoading is true
   */
  loadingLabel?: string
  /**
   * Specify button size
   * @default "medium"
   */
  size?: ResponsiveProp<ButtonSizes>
  /**
   * Specify button style variant
   * @default "primary"
   */
  variant?:
    | 'primary'
    | 'primaryDark'
    | 'secondary'
    | 'secondaryDark'
    | 'tertiary'
} & LoadingProps &
  (AsButtonProps | AsLinkProps)

const getLoadingVariant = (variant: ButtonProps['variant']) => {
  if (variant === 'primary') return 'light'
  if (variant === 'secondary') return 'primary'

  return 'secondary'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = 'button',
      children,
      isFullWidth = false,
      isLoading = false,
      loadingLabel = '',
      size = 'medium',
      variant = 'primary',
      'aria-label': ariaLabel,
      onClick,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    return (
      <ButtonBase
        $as={as}
        $isFullWidth={isFullWidth}
        $isLoading={isLoading}
        $size={size}
        $variant={variant}
        aria-busy={isLoading}
        aria-label={isLoading ? loadingLabel : ariaLabel}
        as={as}
        onClick={!isLoading ? onClick : undefined}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <Loading size="small" variant={getLoadingVariant(variant)} />
        ) : (
          children
        )}
      </ButtonBase>
    )
  }
)
