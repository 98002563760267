import { type OptimizelyDecision } from '@optimizely/optimizely-sdk'

export const FEATURE_FLAG = {
  HOMEPAGE_ROLLOUT: 'homepage_rollout_2',
  ADSENSE_SRP_EXPERIMENT: 'adsense-srp-experiement',
  AUTOCAN_LANDING_PAGES: 'kcan-24370-autocan-landing-pages',
  CLIENT_SIDE_SRP_LOAD: 'kcan-22967_client_side_srp_load',
  CHAMELEON_TRACKING: 'chameleon_tracking',
  CLARIVOY_SRP: 'clarivoy_srp',
  HOSTED_ICO: 'hosted_ico',
  PERFORMANCE_LISTING_COUNT: 'kcan_23226_performance_listing_count_toggle',
  REGISTRATION_LEGAL_UPDATES: 'registration_legal_updates',
  SRP_ICO: 'srp_ico',
  CAS_BACKFILL_REDUCTION: 'kcan-22549_reduce_admarkt_backfill',
  INDEX_AUTOS_ATTRIBUTES: 'kcan-24704-autos-search-experiment',
  SIBLING_CATEGORY_FACETS: 'kcan-23805-sibling-cat-facets',
  SUPPORT_NEW_SCHEMA_FOR_TOGGLES_FILTER: 'support_new_schema_for_toggles_filters',
  RELEVANCY_SORT_EXPERIMENT: 'kcan-25104-relevancy-sort-experiment',
  SEARCH_FOR_FREE_STUFF: 'kcan-25225-free-stuff-search',
  SEARCH_AROUND_ME_EXP: 'kjca-913-allow-users-to-search-nearby-radius',
  PRICE_DROP_NOTIFICATION: 'price_drop_notification',
  HEADER_SIMPLIFIED: 'header_simplified_v2',
  AUTOS_LANDING_PAGE_EXPERIMENT: 'kjca-1941-autos-landing-page-experiment',
} as const

export const RELEVANCY_SORT_FEATURE_FLAGS = [
  FEATURE_FLAG.RELEVANCY_SORT_EXPERIMENT,
  FEATURE_FLAG.SEARCH_FOR_FREE_STUFF,
] as string[]

export type RuleDecision = {
  isEnabled?: OptimizelyDecision['enabled']
  variationKey: OptimizelyDecision['variationKey']
}

type ValuesType<T> = T[keyof T]

export type FeatureFlag = ValuesType<typeof FEATURE_FLAG>
